.recaptcha {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.recaptchaError {
  color: rgb(202, 64, 64);
}
.successForm {
  color: rgb(24, 243, 71);
}
